<template>
    <div class="row">
        <div class="col-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                <div class="card-body mb-2 pb-0"><h2 class="fw-400 font-md d-block"><b>File URL Generator</b></h2><hr>    
                    <form @submit.prevent="postFileRepo" class="row p-2" style="z-index: 99999">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="row p-auto">
                                <div class="form-group col-lg-12">
                                    <label for="">Filename</label>
                                    <input type="text" class="form-control" required v-model="filename" placeholder="Insert your filename!">
                                </div>
                                <div class="col-lg-12 mb-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card" style="height: 25vh">
                                                <div v-if="files.length">
                                                    <div class="progress" style="height: 7px;">
                                                        <div class="progress-bar progress-bar-striped" :class="{'progress-bar-animated': files[0].active, 'w-50 bg-current' : files[0].active, 'w-100 bg-current': files[0].success, 'w-100 bg-danger' : files[0].error}" role="progressbar" :style="{width: files[0].progress + '%'}"></div>
                                                    </div>
                                                </div>
                                                <div class="text-center py-5">
                                                    <ul v-if="files.length">
                                                        <li>
                                                            <span>{{files[0].name}} - {{files[0].size | sizeFormat}}</span> -
                                                            <!-- <span>{{$formatSize(files[0].size)}}</span> - -->
                                                            <span class="badge badge-danger text-capitalize" v-if="files[0].error">{{files[0].error}}</span>
                                                            <span class="badge badge-success" v-else-if="files[0].success"><i class="fas fa-check"></i></span>
                                                            <div v-else-if="files[0].active" class="spinner-border spinner-border-sm text-warning" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                            <span v-else></span>
                                                        </li>
                                                    </ul>
                                                    <h4 v-else>Input your file !</h4>
                                                    <div class="m-auto">
                                                        <file-upload
                                                        class="btn btn-current mr-1"
                                                        @input="updateFile"
                                                        @input-file="inputFile"
                                                        :custom-action="fileAction"
                                                        ref="upload">
                                                        <i class="fa fa-plus"></i>
                                                        Select files
                                                        </file-upload>

                                                        <a v-if="path" :href="url" target="_blank" class="btn btn-success ml-1"><i class="fas fa-file mr-2"></i>Current File</a>

                                                        <button type="button" class="btn btn-danger ml-1"  v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false">
                                                        <i class="fa fa-stop" aria-hidden="true"></i>
                                                        Stop Upload
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 text-right">
                                    <button class="btn btn-current"><i class="fas fa-save mr-2"></i>Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
import FileUpload from 'vue-upload-component'
export default {
    components: {
        FileUpload,
    },
    data(){
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            files: [],
            filename: '',
            path: '',
        }
    },
    filters:{
        sizeFormat: function (size) {
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + ' KB'
            }
            return size.toString() + ' B'
        }
    },
    computed:{
        url: function(){
            if (this.path != '') {
                return this. this.path
            } else {
                return ''
            }
        }
    },
    methods: {
        async postFileRepo() {
            var data = {
                filename : this.filename,
                path : this.path,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/file-repository`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'File Repository!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'HelpAdmin'})
            }).catch((err) => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'File Repository!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        updateFile(value) {
            this.files = value
        },
        async fileAction(file) {
            return this.postFile(file)
        },
        async inputFile(newFile, oldFile) {
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir', 'epub-storage');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
                file.response = res.data
                this.path = res.data.path
                return res.data
            }).catch(err => {
                console.log(err.response)
            })
        },
    }
}
</script>